<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.name"
                dense
                :disabled="readOnly"
                :label="$t('name_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.address"
                dense
                :disabled="readOnly"
                :label="$t('address_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.country_code"
                dense
                :disabled="readOnly"
                :items="['+92']"
                item-value="id"
                item-text="name"
                :label="$t('country_code_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.phone_number"
                dense
                :disabled="readOnly"
                :label="$t('phone_number_*')"
                :rules="[
                  rules.required,
                  rules.startFrom(3),
                  rules.min_length(10),
                  rules.max_length(10),
                ]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="12"
            >
              <v-autocomplete
                v-model="options.tenant_id"
                dense
                :loading="tenantLoading"
                :items="tenantOEM"
                :disabled="type == 'edit'"
                item-text="name"
                item-value="id"
                :label="$t('select_tenant_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.latitude"
                dense
                :disabled="readOnly"
                :label="$t('latitude_*')"
                :rules="[rules.required, rules.latlng]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.longitude"
                dense
                :disabled="readOnly"
                :label="$t('longitude_*')"
                :rules="[rules.required, rules.latlng]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-autocomplete
                v-model="options.country_id"
                dense
                :disabled="readOnly"
                :items="countries"
                item-value="id"
                item-text="name"
                :label="$t('select_country_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-autocomplete
                v-model="options.state_id"
                dense
                :items="states"
                item-value="id"
                item-text="name"
                :disabled="!options.country_id || readOnly"
                :label="$t('select_state_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-autocomplete
                v-model="options.city_id"
                dense
                :items="cities"
                item-value="id"
                item-text="name"
                :disabled="!options.state_id || readOnly"
                :label="$t('select_city_*')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.time_zone"
                dense
                disabled
                :label="$t('timezone')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.status"
                dense
                :disabled="readOnly"
                :items="statusOptions"
                item-value="id"
                item-text="name"
                :label="$t('status_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-menu
                ref="cOpen"
                v-model="isOpenTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="options.opening_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="options.opening_time"
                    dense
                    :disabled="readOnly"
                    :label="$t('open_time_*')"
                    :rules="[rules.required]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="isOpenTime"
                  v-model="otime"
                  format="24hr"
                  scrollable
                  full-width
                  @click:minute="$refs.cOpen.save(otime)"
                  @change="() => (options.closing_time = null)"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-menu
                ref="cTime"
                v-model="isCloseTime"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="options.closing_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="options.closing_time"
                    dense
                    :disabled="readOnly"
                    :label="$t('close_time_*')"
                    readonly
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="isCloseTime"
                  v-model="ctime"
                  format="24hr"
                  :min="options.opening_time"
                  scrollable
                  full-width
                  @click:minute="$refs.cTime.save(ctime)"
                />
              </v-menu>
            </v-col>
            <!--  -->

            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="options.notes"
                dense
                :disabled="readOnly"
                :label="$t('notes')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {},
      //
      otime: null,
      ctime: null,
      //
      isOpenTime: false,
      isCloseTime: false,
      //
      statusOptions: [
        {
          id: "1",
          name: "active",
        },
        {
          id: "0",
          name: "inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantsList",
      //
      countries: "getCountries",
      states: "getStates",
      cities: "getCities",
    }),
    tenantOEM() {
      return this.tenantsList.filter((r) => r.product == "oem");
    },
    title() {
      if (this.type == "create") {
        return "add_new";
      } else {
        return "";
      }
    },
  },
  watch: {
    "options.country_id": {
      handler: async function (val) {
        await this.$store.dispatch("setStates", { country_id: val });
      },
    },
    "options.state_id": {
      handler: async function (val) {
        await this.$store.dispatch("setCities", { state_id: val });
      },
    },
  },
  async mounted() {
    if (this.type == "edit") {
      this.options = { ...this.item };
    }
    this.options.time_zone = "Asia/Karachi";
    // get counteries list
    await this.$store.dispatch("setCountries");
  },
  methods: {
    saveItem() {
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      this.$refs.form.reset();
      this.$emit("onclose");
    },
  },
};
</script>
